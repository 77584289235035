import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import StateReqType from "../types/req/StateReqType";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import StateListResType from "../types/res/list/StateListResType";
import StateResType from "../types/res/StateResType";

export default class StateApi {
	static async getStates() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.STATE.name), requestOptions);
		const listState: StateListResType[] = await res.json();
		return listState
	}

	static async createState(state: StateReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(state)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.STATE.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateState(id: number, state: StateReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(state)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.STATE.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getState(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.STATE.name, id), requestOptions);
		const data: StateResType = await res.json();
		return data;

	}
}
