import { useEffect, useState } from "react";
import CountryApi from "../../api/CountryApi";
import CustomerApi from "../../api/CustomerApi";
import StateApi from "../../api/StateApi";
import TransporterApi from "../../api/TransporterApi";
import MessageDialog from "../../dialogs/MessageDialog";
import { OpeningBalanceTypeEnum } from "../../enums/OpeningBalanceTypeEnum";
import CountryHelper from "../../helpers/CountryHelper";
import OpeningBalanceTypeHelper from "../../helpers/OpeningBalanceTypeHelper";
import StateHelper from "../../helpers/StateHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import TransporterHelper from "../../helpers/TransporterHelper";
import DropDownItemType from "../../types/DropDownItemType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import CountryLdto from "../../types/res/list/CountryLdto";
import StateListResType from "../../types/res/list/StateListResType";
import TransporterListResType from "../../types/res/list/TransporterListResType";
import CustomerRrDto from "../../types/rr/CustomerRrDto";
import DownshiftSelect from "../DownshiftSelectV2";
import TextBox from "../controls/TextBox";

interface AddCustomerProps {
	id?: number
}

function AddCustomer({
	id
}: AddCustomerProps): JSX.Element {

	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [stateId, setStateId] = useState<number | null>(null);
	const [countryId, setCountryId] = useState<number | null>(null);
	const [pin, setPin] = useState("");
	const [email, setEmail] = useState("");
	const [gstNo, setGstNo] = useState("");
	const [isShippingSameAsBilling, setIsShippingSameAsBilling] = useState(true);
	const [shippingName, setShippingName] = useState("");
	const [shippingAddress, setShippingAddress] = useState("");
	const [shippingCity, setShippingCity] = useState("");
	const [shippingStateId, setShippingStateId] = useState<number | null>(null);
	const [shippingCountryId, setShippingCountryId] = useState<number | null>(null);
	const [shippingPin, setShippingPin] = useState("");
	const [shippingEmail, setShippingEmail] = useState("");
	const [shippingGstNo, setShippingGstNo] = useState("");
	const [shippingContactNo, setShippingContactNo] = useState("");
	const [bankName, setBankName] = useState("");
	const [bankBranchName, setBankBranchName] = useState("");
	const [bankAcctName, setBankAcctName] = useState("");
	const [bankAcctNo, setBankAcctNo] = useState("");
	const [bankIfsc, setBankIfsc] = useState("");
	const [creditDaysStr, setCreditDaysStr] = useState("");
	const [creditAmtLimitStr, setCreditAmtLimitStr] = useState("");
	const [discStr, setDiscStr] = useState("");
	const [prefTransId, setPrefTransId] = useState<number | null>(null);
	const [prefAgentId, setPrefAgentId] = useState<number | null>(null);
	const [note, setNote] = useState("");
	const [obStr, setObStr] = useState("");
	const [obTypeId, setObTypeId] = useState<number | null>(null);
	//
	const [stateListResArr, setStateListArr] = useState<StateListResType[]>([]);
	const [stateListResArrForDd, setStateListArrForDd] = useState<DropDownItemType[]>([]);
	const [stateListResMap, setStateListResMap] = useState<Map<number, StateListResType>>(new Map());

	const [countryLdtos, setCountryLdtos] = useState<CountryLdto[]>([]);
	const [countryDdis, setCountryDdis] = useState<DropDownItemType[]>([]);
	const [countryLdtoMap, setCountryLdtoMap] = useState<Map<number, CountryLdto>>(new Map());

	const [transporterListResArr, setTransporterListArr] = useState<TransporterListResType[]>([]);
	const [transporterListResArrForDd, setTransporterListArrForDd] = useState<DropDownItemType[]>([]);
	const [transporterListResMap, setTransporterListResMap] = useState<Map<number, TransporterListResType>>(new Map());

	const [obTypeArrForDd, setObTypeArrForDd] = useState<DropDownItemType[]>([]);

	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	//
	useEffect(() => {
		async function process() {


			StateApi.getStates().then((stateListResArr) => {
				setStateListArr(stateListResArr);
				setStateListArrForDd(StateHelper.getStateListResArrForDd(stateListResArr));
				setStateListResMap(StateHelper.getStateListResMap(stateListResArr));
			});

			CountryApi.getCountryLdtos().then((response) => {
				if (response.isSuccess) {
					setCountryLdtos(response.items!);
					setCountryDdis(CountryHelper.getCountryLdtoDdis(response.items!));
					setCountryLdtoMap(CountryHelper.getCountryLdtoMap(response.items!));
				}
			});

			TransporterApi.getTransporters().then((transporterListResArr) => {
				setTransporterListArr(transporterListResArr);
				setTransporterListArrForDd(TransporterHelper.getTransporterListResArrForDd(transporterListResArr));
				setTransporterListResMap(TransporterHelper.getTransporterListResMap(transporterListResArr));
			});

			if (id !== undefined && id !== null) {
				fillInUi(await CustomerApi.getCustomer(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let customerReq: CustomerRrDto = {
			name: name,
			address: address,
			city: city,
			stateId: stateId === -1 ? null : stateId,
			countryId: countryId === -1 ? null : countryId,
			pin: pin,
			email: email,
			gstNo: gstNo,
			isShippingSameAsBilling: isShippingSameAsBilling,
			shippingName: shippingName,
			shippingAddress: shippingAddress,
			shippingCity: shippingCity,
			shippingStateId: shippingStateId === -1 ? null : shippingStateId,
			shippingCountryId: shippingCountryId === -1 ? null : shippingCountryId,
			shippingPin: shippingPin,
			shippingEmail: shippingEmail,
			shippingGstNo: shippingGstNo,
			shippingContactNo: shippingContactNo,
			bankName: bankName,
			bankBranchName: bankBranchName,
			bankAcctName: bankAcctName,
			bankAcctNo: bankAcctNo,
			bankIfsc: bankIfsc,
			creditDays: creditDaysStr === null ? null : parseFloat(creditDaysStr),
			creditAmtLimit: creditAmtLimitStr === null ? null : parseFloat(creditAmtLimitStr),
			disc: discStr === null ? null : parseFloat(discStr),
			prefTransId: prefTransId === -1 ? null : prefTransId,
			prefAgentId: prefAgentId === -1 ? null : prefAgentId,
			note: note,
			ob: obStr == null || obStr.trim() === "" ? null : parseFloat(obStr),
			obTypeId: obTypeId === -1 ? null : obTypeId,
		}

		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await CustomerApi.createCustomer(customerReq);
		}
		else {
			res = await CustomerApi.updateCustomer(id, customerReq);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}
	}

	const fillInUi = (customerRrDto: CustomerRrDto) => {
		setName(customerRrDto.name);
		setAddress(customerRrDto.address);
		setCity(customerRrDto.city);
		if (customerRrDto.stateId != null)
			setStateId(customerRrDto.stateId);
		if (customerRrDto.countryId != null)
			setCountryId(customerRrDto.countryId);
		setPin(customerRrDto.pin);
		setEmail(customerRrDto.email);
		setGstNo(customerRrDto.gstNo);
		setIsShippingSameAsBilling(customerRrDto.isShippingSameAsBilling);
		setShippingName(customerRrDto.shippingName);
		setShippingAddress(customerRrDto.shippingAddress);
		setShippingCity(customerRrDto.shippingCity);
		if (customerRrDto.shippingStateId != null)
			setShippingStateId(customerRrDto.shippingStateId);
		if (customerRrDto.shippingCountryId != null)
			setShippingCountryId(customerRrDto.shippingCountryId);
		setShippingPin(customerRrDto.shippingPin);
		setShippingEmail(customerRrDto.shippingEmail);
		setShippingGstNo(customerRrDto.shippingGstNo);
		setShippingContactNo(customerRrDto.shippingContactNo);
		setBankName(customerRrDto.bankName);
		setBankBranchName(customerRrDto.bankBranchName);
		setBankAcctName(customerRrDto.bankAcctName);
		setBankAcctNo(customerRrDto.bankAcctNo);
		setBankIfsc(customerRrDto.bankIfsc);
		if (customerRrDto.creditDays != null)
			setCreditDaysStr(customerRrDto.creditDays.toString());
		if (customerRrDto.creditAmtLimit != null)
			setCreditAmtLimitStr(customerRrDto.creditAmtLimit.toString());
		if (customerRrDto.disc != null)
			setDiscStr(customerRrDto.disc.toString());
		if (customerRrDto.prefTransId != null)
			setPrefTransId(customerRrDto.prefTransId);
		if (customerRrDto.prefAgentId != null)
			setPrefAgentId(customerRrDto.prefAgentId);
		setNote(customerRrDto.note);
		if (customerRrDto.ob != null)
			setObStr(customerRrDto.ob.toString());
		if (customerRrDto.obTypeId != null)
			setObTypeId(customerRrDto.obTypeId);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setAddress("");
		setCity("");
		setStateId(-1);
		setPin("");
		setEmail("");
		setGstNo("");
		setIsShippingSameAsBilling(true);
		setShippingName("");
		setShippingAddress("");
		setShippingCity("");
		setShippingStateId(-1);
		setShippingPin("");
		setShippingEmail("");
		setShippingGstNo("");
		setShippingContactNo("");
		setBankName("");
		setBankBranchName("");
		setBankAcctName("");
		setBankAcctNo("");
		setBankIfsc("");
		setCreditDaysStr("");
		setCreditAmtLimitStr("");
		setDiscStr("");
		setPrefTransId(-1);
		setPrefAgentId(-1);
		setNote("");
		setObStr("");
		setObTypeId(-1);
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			creditDaysStr != null
			&& creditDaysStr !== ""
			&& !StringHelper2.isInt(creditDaysStr.trim())
		) {
			setMessageDialogMessage("Invalid credit days");
			setShowMessageDialog(true);
			return false;
		}

		if (
			creditAmtLimitStr != null
			&& creditAmtLimitStr !== ""
			&& !StringHelper2.isNum(creditAmtLimitStr.trim())
		) {
			setMessageDialogMessage("Invalid credit amount limit");
			setShowMessageDialog(true);
			return false;
		}

		if (
			discStr != null
			&& discStr !== ""
			&& !StringHelper2.isNum(discStr.trim())
		) {
			setMessageDialogMessage("Invalid discount");
			setShowMessageDialog(true);
			return false;
		}

		if (
			obStr != null
			&& obStr !== ""
			&& !StringHelper2.isNum(obStr.trim())
		) {
			setMessageDialogMessage("Invalid opening balance");
			setShowMessageDialog(true);
			return false;
		}

		if (
			obStr != null
			&& obStr !== ""
			&& StringHelper2.isNum(obStr.trim())
			&& (obTypeId == null
				|| OpeningBalanceTypeEnum.getById(obTypeId) == null
			)
		) {
			setMessageDialogMessage("Invalid opening balance type");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">

						<TextBox label="Name" value={name} onChange={setName} />
						<TextBox label="Address" value={address} onChange={setAddress} />
						<TextBox label="City" value={city} onChange={setCity} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbCustomer" className="col-form-label">Country</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCustomer"}
									value={countryId != null && countryLdtoMap?.get(countryId) !== null ? CountryHelper.getCountryForDd(countryLdtoMap?.get(countryId)!) : null}
									onChange={setCountryId}
									options={countryDdis}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbState" className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbState"}
									value={stateId != null && stateListResMap?.get(stateId) !== null ? StateHelper.getStateListResForDd(stateListResMap?.get(stateId)!) : null}
									onChange={setStateId}
									options={stateListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="PIN" value={pin} onChange={setPin} />
						<TextBox label="Email" value={email} onChange={setEmail} />
						<TextBox label="GST No." value={gstNo} onChange={setGstNo} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">

							</div>
							<div className="col-sm-10">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={isShippingSameAsBilling}
										onChange={(e) => setIsShippingSameAsBilling(e.target.checked)}
										id="flexCheckDefault"
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault">
										Shpping address same as billing address
									</label>
								</div>
							</div>
						</div>


						<TextBox
							label="Shipping Name"
							value={isShippingSameAsBilling ? name : shippingName}
							onChange={setShippingName}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping Address"
							value={isShippingSameAsBilling ? address : shippingAddress}
							onChange={setShippingAddress}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping City"
							value={isShippingSameAsBilling ? city : shippingCity}
							onChange={setShippingCity}
							disabled={isShippingSameAsBilling}
						/>

						<div className="row align-items-center mb-3" >
							<div className="col-sm-2">
								<label htmlFor="cmbCustomer" className="col-form-label">Shipping Country</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCustomer"}
									value={
										isShippingSameAsBilling ?
											countryId != null && countryLdtoMap?.get(countryId) !== null ? CountryHelper.getCountryForDd(countryLdtoMap?.get(countryId)!) : null :
											shippingCountryId != null && countryLdtoMap?.get(shippingCountryId) !== null ? CountryHelper.getCountryForDd(countryLdtoMap?.get(shippingCountryId)!) : null}
									onChange={setShippingCountryId}
									options={countryDdis}
									isLoading={false}
									isDisabled={isShippingSameAsBilling}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbState" className="col-form-label">Shipping State</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbState"}
									value={
										isShippingSameAsBilling ?
											stateId != null && stateListResMap?.get(stateId) !== null ? StateHelper.getStateListResForDd(stateListResMap?.get(stateId)!) : null :
											shippingStateId != null && stateListResMap?.get(shippingStateId) !== null ? StateHelper.getStateListResForDd(stateListResMap?.get(shippingStateId)!) : null}
									onChange={setShippingStateId}
									options={stateListResArrForDd}
									isLoading={false}
									isDisabled={isShippingSameAsBilling}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox
							label="Shipping PIN"
							value={isShippingSameAsBilling ? pin : shippingPin}
							onChange={setShippingPin}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping Email"
							value={isShippingSameAsBilling ? email : shippingEmail}
							onChange={setShippingEmail}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping GST No."
							value={isShippingSameAsBilling ? gstNo : shippingGstNo}
							onChange={setShippingGstNo}
							disabled={isShippingSameAsBilling}
						/>
						<TextBox
							label="Shipping Contact No."
							value={isShippingSameAsBilling ? "" : shippingContactNo}
							onChange={setShippingContactNo}
							disabled={isShippingSameAsBilling}
						/>

						<TextBox label="Bank Name" value={bankName} onChange={setBankName} />
						<TextBox label="Bank Branch Name" value={bankBranchName} onChange={setBankBranchName} />
						<TextBox label="Bank Account Name" value={bankAcctName} onChange={setBankAcctName} />
						<TextBox label="Bank Account No." value={bankAcctNo} onChange={setBankAcctNo} />
						<TextBox label="Bank IFSC" value={bankIfsc} onChange={setBankIfsc} />
						<TextBox label="Credit Days" value={creditDaysStr} onChange={setCreditDaysStr} />
						<TextBox label="Credit Amount" value={creditAmtLimitStr} onChange={setCreditAmtLimitStr} />
						<TextBox label="Discount" value={discStr} onChange={setDiscStr} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbPrefTransporter" className="col-form-label">Preffered Transport Id</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbPrefTransporter"}
									value={prefTransId != null && transporterListResMap?.get(prefTransId) !== null ? TransporterHelper.getTransporterListResForDd(transporterListResMap?.get(prefTransId)!) : null}
									onChange={setPrefTransId}
									options={transporterListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="Note" value={note} onChange={setNote} />

						<TextBox label="Opening Balance" value={obStr} onChange={setObStr} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbObType" className="col-form-label">Opening Balance Type</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbObType"}
									value={obTypeId != null && OpeningBalanceTypeEnum.getById(obTypeId) !== null ? OpeningBalanceTypeHelper.getObTypeForDd(OpeningBalanceTypeEnum.getById(obTypeId)!) : null}
									onChange={setObTypeId}
									options={obTypeArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddCustomer;
