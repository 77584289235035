import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import SupplierReqType from "../types/req/SupplierReqType";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import SupplierListResType from "../types/res/list/SupplierListResType";
import SupplierResType from "../types/res/SupplierResType";

export default class SupplierApi {
	static async getSuppliers() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SUPPLIER.name), requestOptions);
		const listSupplier: SupplierListResType[] = await res.json();
		return listSupplier
	}

	static async createSupplier(supplier: SupplierReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(supplier)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SUPPLIER.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateSupplier(id: number, supplier: SupplierReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(supplier)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SUPPLIER.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getSupplier(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SUPPLIER.name, id), requestOptions);
		const data: SupplierResType = await res.json();
		return data;

	}
}
