import { BASE_URL, TOKEN } from "../config";
import { GetProdObjectTypeEnum } from "../enums/GetProdObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import ProductReqType from "../types/req/ProductReqType";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import ProdForEsimateListResType from "../types/res/list/ProdForEsimateListResType";
import ProductListResType from "../types/res/list/ProductListResType";
import ProductResType from "../types/res/ProductResType";

export default class ProductApi {

	static async getProducts() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name), requestOptions);
		const listProduct: ProductListResType[] = await res.json();
		return listProduct
	}

	static async getProdForEstimate() {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name)
			+ getQueryString({ returnObjectType: GetProdObjectTypeEnum.PROD_FOR_ESTIMATE.enumName.toUpperCase() }),
			requestOptions);
		const listProduct: ProdForEsimateListResType[] = await res.json();
		return listProduct

	}

	static async createProduct(product: ProductReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(product)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateProduct(id: number, product: ProductReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(product)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getProduct(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT.name, id), requestOptions);
		const data: ProductResType = await res.json();
		return data;

	}
}
