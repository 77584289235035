import React, { useEffect, useState } from "react";
import ProductCatApi from "../../api/ProductCatApi";
import MessageDialog from "../../dialogs/MessageDialog";
import ProductCatHelper from "../../helpers/ProductCatHelper";
import DropDownItemType from "../../types/DropDownItemType";
import ProductCatReqType from "../../types/req/ProductCatReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import ProductCatListResType from "../../types/res/list/ProductCatListResType";
import ProductCatResType from "../../types/res/ProductCatResType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddProductCatProps {
	id?: number
}

function AddProductCat({
	id
}: AddProductCatProps): JSX.Element {

	const [name, setName] = useState("");
	const [note, setNote] = useState("");
	const [parentId, setParentId] = useState<number | null>(null);
	//
	const [productCatArr, setProductCatArr] = useState<ProductCatListResType[]>([]);
	const [productCatArrForDd, setProductCatArrForDd] = useState<DropDownItemType[]>([]);
	const [productCatResMap, setProductCatResMap] = useState<Map<number, ProductCatListResType>>(new Map());
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			ProductCatApi.getProductCategorys().then((productCatArr) => {
				setProductCatArr(productCatArr);
				setProductCatArrForDd(ProductCatHelper.getProductCatListForDd(productCatArr));
				setProductCatResMap(ProductCatHelper.getProductCatMap(productCatArr));
			});

			if (id !== undefined && id !== null) {
				fillInUi(await ProductCatApi.getProductCat(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let productCat: ProductCatReqType = {
			name: name,
			note: note,
			parentId: parentId
		};
		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await ProductCatApi.createProductCat(productCat);
		}
		else {
			res = await ProductCatApi.updateProductCat(id, productCat);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (productCatResType: ProductCatResType) => {
		setName(productCatResType.name);
		setNote(productCatResType.note);
		setParentId(productCatResType.parentId ?? -1);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setNote("");
		setParentId(-1);
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			note === undefined
			|| note === null
			|| note === ""
		) {
			setMessageDialogMessage("Invalid code name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			parentId === undefined
			|| parentId === null
			|| productCatResMap.get(parentId) === undefined
			|| productCatResMap.get(parentId) === null
		) {
			setMessageDialogMessage("Invalid parent category");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Code</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={note}
									onChange={(e) => setNote(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbProductCat" className="col-form-label">Parent Category</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={parentId != null && productCatResMap?.get(parentId) !== null ? ProductCatHelper.getProductCatForDd(productCatResMap?.get(parentId)!) : null}
									onChange={setParentId}
									options={productCatArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>
					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddProductCat;
