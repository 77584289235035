import { useEffect, useState } from "react";
import StateApi from "../../api/StateApi";
import SupplierApi from "../../api/SupplierApi";
import MessageDialog from "../../dialogs/MessageDialog";
import { OpeningBalanceTypeEnum } from "../../enums/OpeningBalanceTypeEnum";
import OpeningBalanceTypeHelper from "../../helpers/OpeningBalanceTypeHelper";
import StateHelper from "../../helpers/StateHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import DropDownItemType from "../../types/DropDownItemType";
import SupplierReqType from "../../types/req/SupplierReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import SupplierResType from "../../types/res/SupplierResType";
import StateListResType from "../../types/res/list/StateListResType";
import DownshiftSelect from "../DownshiftSelectV2";
import TextBox from "../controls/TextBox";

interface AddSupplierProps {
	id?: number
}

function AddSupplier({
	id
}: AddSupplierProps): JSX.Element {

	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [stateId, setStateId] = useState<number | null>(null);
	const [pin, setPin] = useState("");
	const [email, setEmail] = useState("");
	const [gstNo, setGstNo] = useState("");
	const [bankName, setBankName] = useState("");
	const [bankBranchName, setBankBranchName] = useState("");
	const [bankAcctName, setBankAcctName] = useState("");
	const [bankAcctNo, setBankAcctNo] = useState("");
	const [bankIfsc, setBankIfsc] = useState("");
	const [creditDaysStr, setCreditDaysStr] = useState("");
	const [deliveryDaysStr, setDeliveryDaysStr] = useState("");
	const [note, setNote] = useState("");
	const [obStr, setObStr] = useState("");
	const [obTypeId, setObTypeId] = useState<number | null>(null);
	//
	const [stateListResArr, setStateListArr] = useState<StateListResType[]>([]);
	const [stateListResArrForDd, setStateListArrForDd] = useState<DropDownItemType[]>([]);
	const [stateListResMap, setStateListResMap] = useState<Map<number, StateListResType>>(new Map());

	const [obTypeArrForDd, setObTypeArrForDd] = useState<DropDownItemType[]>([]);

	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	//
	useEffect(() => {
		async function process() {

			StateApi.getStates().then((stateListResArr) => {
				setStateListArr(stateListResArr);
				setStateListArrForDd(StateHelper.getStateListResArrForDd(stateListResArr));
				setStateListResMap(StateHelper.getStateListResMap(stateListResArr));
			});

			setObTypeArrForDd(OpeningBalanceTypeHelper.getObTypeArrForDd());

			if (id !== undefined && id !== null) {
				fillInUi(await SupplierApi.getSupplier(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let supplierReq: SupplierReqType = {
			name: name,
			address: address,
			city: city,
			stateId: stateId === -1 ? null : stateId,
			pin: pin === null ? null : pin,
			email: email,
			gstNo: gstNo,
			bankName: bankName,
			bankBranchName: bankBranchName,
			bankAcctName: bankAcctName,
			bankAcctNo: bankAcctNo,
			bankIfsc: bankIfsc,
			creditDays: creditDaysStr == null || creditDaysStr.trim() === "" ? null : parseInt(creditDaysStr),
			deliveryDays: deliveryDaysStr == null || deliveryDaysStr.trim() === "" ? null : parseInt(deliveryDaysStr),
			note: note,
			ob: obStr == null || obStr.trim() === "" ? null : parseFloat(obStr),
			obTypeId: obTypeId === -1 ? null : obTypeId,
		}

		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await SupplierApi.createSupplier(supplierReq);
		}
		else {
			res = await SupplierApi.updateSupplier(id, supplierReq);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}
	}

	const fillInUi = (supplierResType: SupplierResType) => {
		setName(supplierResType.name);
		setAddress(supplierResType.address);
		setCity(supplierResType.city);
		if (supplierResType.stateId != null)
			setStateId(supplierResType.stateId);
		setPin(supplierResType.pin);
		setEmail(supplierResType.email);
		setGstNo(supplierResType.gstNo);
		setBankName(supplierResType.bankName);
		setBankBranchName(supplierResType.bankBranchName);
		setBankAcctName(supplierResType.bankAcctName);
		setBankAcctNo(supplierResType.bankAcctNo);
		setBankIfsc(supplierResType.bankIfsc);
		if (supplierResType.creditDays != null)
			setCreditDaysStr(supplierResType.creditDays.toString());
		if (supplierResType.deliveryDays != null)
			setDeliveryDaysStr(supplierResType.deliveryDays.toString());
		setNote(supplierResType.note);
		if (supplierResType.ob != null)
			setObStr(supplierResType.ob.toString());
		if (supplierResType.obTypeId != null)
			setObTypeId(supplierResType.obTypeId);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setAddress("");
		setCity("");
		setStateId(-1);
		setPin("");
		setEmail("");
		setGstNo("");
		setBankName("");
		setBankBranchName("");
		setBankAcctName("");
		setBankAcctNo("");
		setBankIfsc("");
		setCreditDaysStr("");
		setDeliveryDaysStr("");
		setNote("");
		setObStr("");
		setObTypeId(-1);
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			stateId != null
			&& stateListResMap.get(stateId) == null
		) {
			setMessageDialogMessage("Invalid state");
			setShowMessageDialog(true);
			return false;
		}

		if (
			creditDaysStr != null
			&& creditDaysStr !== ""
			&& !StringHelper2.isInt(creditDaysStr.trim())
		) {
			setMessageDialogMessage("Invalid credit days");
			setShowMessageDialog(true);
			return false;
		}

		if (
			deliveryDaysStr != null
			&& deliveryDaysStr !== ""
			&& !StringHelper2.isInt(deliveryDaysStr.trim())
		) {
			setMessageDialogMessage("Invalid delivery days");
			setShowMessageDialog(true);
			return false;
		}

		if (
			obStr != null
			&& obStr !== ""
			&& !StringHelper2.isNum(obStr.trim())
		) {
			setMessageDialogMessage("Invalid opening balance");
			setShowMessageDialog(true);
			return false;
		}

		if (
			obStr != null
			&& obStr !== ""
			&& StringHelper2.isNum(obStr.trim())
			&& (obTypeId == null
				|| OpeningBalanceTypeEnum.getById(obTypeId) == null
			)
		) {
			setMessageDialogMessage("Invalid opening balance type");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">

						<TextBox label="Name" value={name} onChange={setName} />
						<TextBox label="Address" value={address} onChange={setAddress} />
						<TextBox label="City" value={city} onChange={setCity} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbState" className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbState"}
									value={stateId != null && stateListResMap?.get(stateId) != null ? StateHelper.getStateListResForDd(stateListResMap?.get(stateId)!) : null}
									onChange={setStateId}
									options={stateListResArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<TextBox label="PIN" value={pin} onChange={setPin} />
						<TextBox label="Email" value={email} onChange={setEmail} />
						<TextBox label="GST No." value={gstNo} onChange={setGstNo} />
						<TextBox label="Bank Name" value={bankName} onChange={setBankName} />
						<TextBox label="Bank Branch Name" value={bankBranchName} onChange={setBankBranchName} />
						<TextBox label="Bank Account Name" value={bankAcctName} onChange={setBankAcctName} />
						<TextBox label="Bank Account No." value={bankAcctNo} onChange={setBankAcctNo} />
						<TextBox label="Bank IFSC" value={bankIfsc} onChange={setBankIfsc} />
						<TextBox label="Credit Days" value={creditDaysStr} onChange={setCreditDaysStr} />
						<TextBox label="Delivery Days" value={deliveryDaysStr} onChange={setDeliveryDaysStr} />
						<TextBox label="Note" value={note} onChange={setNote} />
						<TextBox label="Opening Balance" value={obStr} onChange={setObStr} />

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbObType" className="col-form-label">Opening Balance Type</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbObType"}
									value={obTypeId != null && OpeningBalanceTypeEnum.getById(obTypeId) != null ? OpeningBalanceTypeHelper.getObTypeForDd(OpeningBalanceTypeEnum.getById(obTypeId)!) : null}
									onChange={setObTypeId}
									options={obTypeArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddSupplier;
