import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import TaxReqType from "../types/req/TaxReqType";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import TaxListResType from "../types/res/list/TaxListResType";
import TaxResType from "../types/res/TaxResType";

export default class TaxApi {
	static async getTaxes() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "appliion/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TAX.name), requestOptions);
		const listTax: TaxListResType[] = await res.json();
		return listTax
	}

	static async createTax(tax: TaxReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(tax)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TAX.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateTax(id: number, tax: TaxReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(tax)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.TAX.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getTax(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.TAX.name, id), requestOptions);
		const data: TaxResType = await res.json();
		return data;

	}
}
