import { OpeningBalanceTypeEnum } from "../enums/OpeningBalanceTypeEnum";
import DropDownItemType from "../types/DropDownItemType";

export default class OpeningBalanceTypeHelper {

	static getObTypeArrForDd() {
		let finalArr: DropDownItemType[] = [];
		OpeningBalanceTypeEnum.values.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;
	}

	static getObTypeForDd(openingBalanceType: OpeningBalanceTypeEnum): DropDownItemType {
		return {
			id: openingBalanceType.id,
			name: openingBalanceType.name
		};
	}

}