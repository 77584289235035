import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import ProductCatReqType from "../types/req/ProductCatReqType";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import ProductCatListResType from "../types/res/list/ProductCatListResType";
import ProductCatResType from "../types/res/ProductCatResType";

export default class ProductCatApi {
	
	static async getProductCategorys() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT_CAT.name), requestOptions);
		const listProductCategory: ProductCatListResType[] = await res.json();
		return listProductCategory
	}

	static async createProductCat(productcat: ProductCatReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(productcat)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT_CAT.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateProductCat(id: number, productcat: ProductCatReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(productcat)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT_CAT.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getProductCat(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PRODUCT_CAT.name, id), requestOptions);
		const data: ProductCatResType = await res.json();
		return data;

	}
}
