export class Layout {

	public static readonly COMMNC_BOOK = new Layout(15, "commnc_book", "Communication Book");
	public static readonly REMINDER = new Layout(16, "reminder", "Reminder");
	public static readonly COMMNC = new Layout(17, "commnc", "Communication");
	public static readonly LEAD = new Layout(18, "lead", "Lead");
	public static readonly ACCT_BOOK = new Layout(19, "acct_book", "Account Book");
	public static readonly DAY_BOOK = new Layout(20, "day_book", "Day Book");
	public static readonly CDP_JOURNAL_VOU = new Layout(21, "cdp_journal_vou", "Cash Discount / Penalty");
	public static readonly JOURNAL = new Layout(22, "journal", "Journal");
	public static readonly CONTRA = new Layout(23, "contra", "Contra");
	public static readonly RECEIPT = new Layout(24, "receipt", "Receipt");
	public static readonly PAYMENT = new Layout(25, "payment", "Payment");
	public static readonly SALES_RETURN = new Layout(26, "sales_return", "Sales Return");
	public static readonly SALES = new Layout(27, "sales", "Sales");
	public static readonly PI = new Layout(28, "pi", "Proforma Invoice");
	public static readonly SALES_ORDER = new Layout(29, "sales_order", "Sales Order");
	public static readonly ESTIMATE = new Layout(30, "estimate", "Estimate");
	public static readonly PURCHASE_RETURN = new Layout(31, "purchase_return", "Purchase Return");
	public static readonly PURCHASE = new Layout(32, "purchase", "Purchase");
	public static readonly PURCHASE_ORDER = new Layout(33, "purchase_order", "Purchase Order");
	public static readonly COMM_VOU = new Layout(34, "comm_vou", "Commission Voucher");
	public static readonly CDP_VOU = new Layout(35, "cdp_vou", "Cash Discount Voucher");
	public static readonly ORDER_ADJUST = new Layout(36, "order_adjust", "Order Adjust");
	public static readonly BANK = new Layout(37, "bank", "Bank");
	public static readonly CUSTOMER = new Layout(38, "customer", "Customer");
	public static readonly SUPPLIER = new Layout(39, "supplier", "Supplier");
	public static readonly AGENT = new Layout(40, "agent", "Agent");
	public static readonly ACCT = new Layout(41, "acct", "Account");
	public static readonly ACCT_TYPE = new Layout(42, "acct_type", "Account Type");
	public static readonly INV_ADJUST = new Layout(43, "inv_adjust", "Inventory Adjust");
	public static readonly COMM_LIST = new Layout(44, "comm_list", "Commission List");
	public static readonly PURCHASE_PRICE_LIST = new Layout(45, "purchase_price_list", "Purchase Price List");
	public static readonly SALES_PRICE_LIST = new Layout(46, "sales_price_list", "Sales Price List");
	public static readonly SERVICE = new Layout(47, "service", "Service");
	public static readonly PRODUCT = new Layout(48, "product", "Product");
	public static readonly PROD_OTH_CH = new Layout(49, "prod_oth_ch", "Product Other Charge");
	public static readonly TAX_CAT = new Layout(50, "tax_cat", "Tax Category");
	public static readonly PRODUCT_CAT = new Layout(51, "product_cat", "Category");
	public static readonly UNIT = new Layout(52, "unit", "Unit");
	public static readonly RPT_SALES_RETURN_BY_MONTH = new Layout(53, "rpt_sales_return_by_month", "By Month");
	public static readonly RPT_SALES_RETURN_BY_DAY = new Layout(54, "rpt_sales_return_by_day", "By Day");
	public static readonly RPT_SALES_RETURN_BY_CUSTOMER = new Layout(55, "rpt_sales_return_by_customer", "By Customer");
	public static readonly RPT_SALES_RETURN_BY_CATEGORY = new Layout(56, "rpt_sales_return_by_category", "By Category");
	public static readonly RPT_SALES_RETURN_BY_PRODUCT = new Layout(57, "rpt_sales_return_by_product", "By Product");
	public static readonly RPT_SALES_RETURN_BY_CITY = new Layout(58, "rpt_sales_return_by_city", "By City");
	public static readonly RPT_SALES_RETURN_BY_STATE = new Layout(59, "rpt_sales_return_by_state", "By State");
	public static readonly RPT_SALES_RETURN_BY_AGENT = new Layout(60, "rpt_sales_return_by_agent", "By Agent");
	public static readonly RPT_SALES_RETURN_DETAILED_BY_VOUCHER = new Layout(61, "rpt_sales_return_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_SALES_RETURN_DETAILED_BY_PROD = new Layout(62, "rpt_sales_return_detailed_by_prod", "Detailed By Product");
	public static readonly RPT_SALES_BY_MONTH = new Layout(63, "rpt_sales_by_month", "By Month");
	public static readonly RPT_SALES_BY_DAY = new Layout(64, "rpt_sales_by_day", "By Day");
	public static readonly RPT_SALES_BY_CUSTOMER = new Layout(65, "rpt_sales_by_customer", "By Customer");
	public static readonly RPT_SALES_BY_CATEGORY = new Layout(66, "rpt_sales_by_category", "By Category");
	public static readonly RPT_SALES_BY_PRODUCT = new Layout(67, "rpt_sales_by_product", "By Product");
	public static readonly RPT_SALES_BY_CITY = new Layout(68, "rpt_sales_by_city", "By City");
	public static readonly RPT_SALES_BY_STATE = new Layout(69, "rpt_sales_by_state", "By State");
	public static readonly RPT_SALES_BY_AGENT = new Layout(70, "rpt_sales_by_agent", "By Agent");
	public static readonly RPT_SALES_DETAILED_BY_VOUCHER = new Layout(71, "rpt_sales_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_SALES_DETAILED_BY_PROD = new Layout(72, "rpt_sales_detailed_by_prod", "Detailed By Product");
	public static readonly SALES_ANALYSIS = new Layout(73, "sales_analysis", "Sales Analysis");
	public static readonly RPT_SALES_PROFIT_BY_CUSTOMER = new Layout(74, "rpt_sales_profit_by_customer", "By Customer");
	public static readonly RPT_SALES_PROFIT_BY_CATEGORY = new Layout(75, "rpt_sales_profit_by_category", "By Category");
	public static readonly RPT_SALES_PROFIT_BY_PROD = new Layout(76, "rpt_sales_profit_by_prod", "By Product");
	public static readonly RPT_SALES_PROFIT_BY_SALES_VOUCHER = new Layout(77, "rpt_sales_profit_by_sales_voucher", "By Sales Voucher");
	public static readonly RPT_SALES_PROFIT_BY_SALES_ITEM = new Layout(78, "rpt_sales_profit_by_sales_item", "By Sales Item");
	public static readonly RPT_PURCHASE_RETURN_BY_MONTH = new Layout(79, "rpt_purchase_return_by_month", "By Month");
	public static readonly RPT_PURCHASE_RETURN_BY_DAY = new Layout(80, "rpt_purchase_return_by_day", "By Day");
	public static readonly RPT_PURCHASE_RETURN_BY_SUPPLIER = new Layout(81, "rpt_purchase_return_by_supplier", "By Supplier");
	public static readonly RPT_PURCHASE_RETURN_BY_CATEGORY = new Layout(82, "rpt_purchase_return_by_category", "By Category");
	public static readonly RPT_PURCHASE_RETURN_BY_PRODUCT = new Layout(83, "rpt_purchase_return_by_product", "By Product");
	public static readonly RPT_PURCHASE_RETURN_BY_CITY = new Layout(84, "rpt_purchase_return_by_city", "By City");
	public static readonly RPT_PURCHASE_RETURN_BY_STATE = new Layout(85, "rpt_purchase_return_by_state", "By State");
	public static readonly RPT_PURCHASE_RETURN_DETAILED_BY_VOUCHER = new Layout(86, "rpt_purchase_return_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_PURCHASE_RETURN_DETAILED_BY_PROD = new Layout(87, "rpt_purchase_return_detailed_by_prod", "Detailed By Product");
	public static readonly RPT_PURCHASE_BY_MONTH = new Layout(88, "rpt_purchase_by_month", "By Month");
	public static readonly RPT_PURCHASE_BY_DAY = new Layout(89, "rpt_purchase_by_day", "By Day");
	public static readonly RPT_PURCHASE_BY_SUPPLIER = new Layout(90, "rpt_purchase_by_supplier", "By Supplier");
	public static readonly RPT_PURCHASE_BY_CATEGORY = new Layout(91, "rpt_purchase_by_category", "By Category");
	public static readonly RPT_PURCHASE_BY_PRODUCT = new Layout(92, "rpt_purchase_by_product", "By Product");
	public static readonly RPT_PURCHASE_BY_CITY = new Layout(93, "rpt_purchase_by_city", "By City");
	public static readonly RPT_PURCHASE_BY_STATE = new Layout(94, "rpt_purchase_by_state", "By State");
	public static readonly RPT_PURCHASE_DETAILED_BY_VOUCHER = new Layout(95, "rpt_purchase_detailed_by_voucher", "Detailed By Voucher");
	public static readonly RPT_PURCHASE_DETAILED_BY_PROD = new Layout(96, "rpt_purchase_detailed_by_prod", "Detailed By Product");
	public static readonly RPT_ESTIMATE_PENDING_BY_PROD = new Layout(97, "rpt_estimate_pending_by_prod", "By Product");
	public static readonly RPT_ESTIMATE_PENDING_BY_ACCT = new Layout(98, "rpt_estimate_pending_by_acct", "By Customer");
	public static readonly RPT_ESTIMATE_PENDING_PRODUCTS = new Layout(99, "rpt_estimate_pending_products", "Pending Products");
	public static readonly DUE_RCVABLE_BY_CUSTOMER = new Layout(100, "due_rcvable_by_customer", "By Customer");
	public static readonly DUE_RCVABLE_BY_SALES = new Layout(101, "due_rcvable_by_sales", "By Sales");
	public static readonly RPT_IO_SUMM_BY_PROD_CAT = new Layout(102, "rpt_io_summ_by_prod_cat", "By Category");
	public static readonly RPT_IO_SUMM_BY_MONTH = new Layout(103, "rpt_io_summ_by_month", "By Month");
	public static readonly RPT_IO_SUMM_BY_PROD = new Layout(104, "rpt_io_summ_by_prod", "By Product");
	public static readonly RPT_IO_DET = new Layout(105, "rpt_io_det", "In/Out Detailed");
	public static readonly RPT_INVENTORY_TURNOVER = new Layout(106, "rpt_inventory_turnover", "Inventory Turnover");
	public static readonly LEDGER = new Layout(107, "ledger", "Ledger");
	public static readonly ACCT_SUMM_BY_ACCT_TYPE = new Layout(108, "acct_summ_by_acct_type", "Account Summary");
	public static readonly TRIAL_BAL = new Layout(109, "trial_bal", "Trial Balance");
	public static readonly TRADING_ACCT = new Layout(110, "trading_acct", "Trading Account");
	public static readonly PL_ACCT = new Layout(111, "pl_acct", "PL Account");
	public static readonly BALANCE_SHEET = new Layout(112, "balance_sheet", "Balance Sheet");
	public static readonly GSTR3B = new Layout(113, "gstr3b", "GSTR3B");
	public static readonly INVENTORY_LEDGER = new Layout(114, "inventory_ledger", "Inventory Ledger");
	public static readonly CURRENT_STOCK = new Layout(115, "current_stock", "Current Stock");
	public static readonly CURRENT_STOCK_VAL = new Layout(116, "current_stock_val", "Current Stock Value");
	public static readonly LOW_STOCK = new Layout(117, "low_stock", "Low Stock");
	public static readonly CDP_VOU_LEDGER = new Layout(118, "cdp_vou_ledger", "Cash Discount Voucher Ledger");
	public static readonly CDP_VOU_LEDGER_2 = new Layout(119, "cdp_vou_ledger_2", "Cash Discount Voucher Ledger 2");
	public static readonly COMM_VOU_LEDGER = new Layout(120, "comm_vou_ledger", "Commission Voucher Ledger");
	public static readonly SALES_ORDER_PENDING = new Layout(121, "sales_order_pending", "Sales Order Pending");
	public static readonly PURCHASE_ORDER_PENDING = new Layout(122, "purchase_order_pending", "Purchase Order Pending");
	public static readonly ACCT_EXPENSE_ANALYSIS = new Layout(123, "acct_expense_analysis", "Acct. Expense Analysis");
	public static readonly MSG_QUEUE = new Layout(124, "msg_queue", "Message Queue");
	public static readonly SCHEME = new Layout(125, "scheme", "Scheme");
	public static readonly TRANSPORTER = new Layout(126, "transporter", "Transporter");
	public static readonly TAX_CAL_RATE = new Layout(127, "tax_cal_rate", "Tax Calculation Rate");
	public static readonly TAX = new Layout(128, "tax", "Tax");
	public static readonly MSG_TEMPLATE = new Layout(129, "msg_template", "Message Template");
	public static readonly BROWSER = new Layout(130, "browser", "Browser");
	public static readonly COMPANY_INFO = new Layout(131, "company_info", "Company Information");
	public static readonly COMPANY_PROFILE = new Layout(132, "company_profile", "Company Profile");
	public static readonly VOU_OTH_CH = new Layout(133, "vou_oth_ch", "Voucher Other Charge");
	public static readonly LEAD_SRC = new Layout(134, "lead_src", "Lead Source");
	public static readonly LEAD_STATUS = new Layout(135, "lead_status", "Lead Status");
	public static readonly COUNTRY = new Layout(136, "country", "Country");
	public static readonly STATE = new Layout(137, "state", "State");
	public static readonly CURRENCY = new Layout(138, "currency", "Currency");
	public static readonly PAYMENT_MODE = new Layout(139, "payment_mode", "Payment Mode");
	public static readonly PAYMENT_TERMS = new Layout(140, "payment_terms", "Payment Terms");
	public static readonly TRADE_TERMS = new Layout(141, "trade_terms", "Trade Terms");
	public static readonly SALES_CHANNEL = new Layout(142, "sales_channel", "Sales Channel");
	public static readonly IMPORT_DATA = new Layout(143, "import_data", "Import Data");
	public static readonly MENU_CONFIG = new Layout(144, "menu_config", "Menu Config");

	static get values(): Layout[] {
		return [
			// this.FRANCE,
			// this.GERMANY,
			// this.ITALY,
			// this.SPAIN
		];
	}

	private constructor(
		public readonly id: number,
		public readonly key: string,
		public readonly displayName: string
	) {
	}
}