import React, { useEffect, useState } from "react";
import SalesChannelApi from "../../api/SalesChannelApi";
import MessageDialog from "../../dialogs/MessageDialog";
import SalesChannelReqType from "../../types/req/SalesChannelReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import SalesChannelResType from "../../types/res/SalesChannelResType";

interface AddSalesChannelProps {
	id?: number
}

function AddSalesChannel({
	id
}: AddSalesChannelProps): JSX.Element {

	const [name, setName] = useState("");
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			if (id !== undefined && id !== null) {
				fillInUi(await SalesChannelApi.getSalesChannel(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let salesChannel: SalesChannelReqType = {
			name: name,
		};
		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await SalesChannelApi.createSalesChannel(salesChannel);
		}
		else {
			res = await SalesChannelApi.updateSalesChannel(id, salesChannel);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (salesChannelResType: SalesChannelResType) => {
		setName(salesChannelResType.name);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>

					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddSalesChannel;
