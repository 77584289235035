import React, { useEffect, useState } from "react";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import MessageDialog from "../../dialogs/MessageDialog";
import StringHelper2 from "../../helpers/StringHelper2";
import TaxHelper from "../../helpers/TaxHelper";
import DropDownItemType from "../../types/DropDownItemType";
import TaxCatReqType from "../../types/req/TaxCatReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import TaxCatResType from "../../types/res/TaxCatResType";
import TaxListResType from "../../types/res/list/TaxListResType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddTaxCatProps {
	id?: number
}

function AddTaxCat({
	id
}: AddTaxCatProps): JSX.Element {

	const [name, setName] = useState("");
	const [taxId, setTaxId] = useState<number | null>(null);
	const [hsnStr, setHsnStr] = useState("");
	//
	const [taxListArr, setTaxListArr] = useState<TaxListResType[]>([]);
	const [taxListArrForDd, setTaxListArrForDd] = useState<DropDownItemType[]>([]);
	const [taxListResMap, setTaxListResMap] = useState<Map<number, TaxListResType>>(new Map());
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			TaxApi.getTaxes().then((taxListArr) => {
				setTaxListArr(taxListArr);
				setTaxListArrForDd(TaxHelper.getTaxListForDd(taxListArr));
				setTaxListResMap(TaxHelper.getTaxMap(taxListArr));
			});

			if (id !== undefined && id !== null) {
				fillInUi(await TaxCatApi.getTaxCat(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let taxCat: TaxCatReqType = {
			name: name,
			hsn: parseInt(hsnStr),
			taxId: taxId!
		};
		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await TaxCatApi.createTaxCat(taxCat);
		}
		else {
			res = await TaxCatApi.updateTaxCat(id, taxCat);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (taxCatResType: TaxCatResType) => {
		setName(taxCatResType.name);
		setHsnStr(taxCatResType.hsn.toString());
		setTaxId(taxCatResType.taxId);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setHsnStr("");
		setTaxId(-1);
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			hsnStr === undefined
			|| hsnStr === null
			|| hsnStr === ""
			|| !StringHelper2.isInt(hsnStr)
		) {
			setMessageDialogMessage("Invalid code number");
			setShowMessageDialog(true);
			return false;
		}

		if (
			taxId === undefined
			|| taxId === null
			|| taxListResMap.get(taxId) === undefined
			|| taxListResMap.get(taxId) === null
		) {
			setMessageDialogMessage("Invalid tax");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">HSN</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={hsnStr}
									onChange={(e) => setHsnStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTax" className="col-form-label">Tax</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={taxId != null && taxListResMap?.get(taxId) !== null ? TaxHelper.getTaxForDd(taxListResMap?.get(taxId)!) : null}
									onChange={setTaxId}
									options={taxListArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>
					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddTaxCat;
