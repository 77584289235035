import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import CustomerRrDto from "../types/rr/CustomerRrDto";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import CustomerListResType from "../types/res/list/CustomerListResType";

export default class CustomerApi {
	static async getCustomers() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.CUSTOMER.name), requestOptions);
		const listCustomer: CustomerListResType[] = await res.json();
		return listCustomer
	}

	static async createCustomer(customer: CustomerRrDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(customer)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.CUSTOMER.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateCustomer(id: number, customer: CustomerRrDto): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(customer)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.CUSTOMER.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getCustomer(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.CUSTOMER.name, id), requestOptions);
		const data: CustomerRrDto = await res.json();
		return data;

	}
}
