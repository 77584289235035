import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import PaymentTermReqType from "../types/req/PaymentTermReqType";
import CommonUpsertResType from "../types/res/CommonUpsertResType";
import ErrorResType from "../types/res/ErrorResType";
import PaymentTermListResType from "../types/res/list/PaymentTermListResType";
import PaymentTermResType from "../types/res/PaymentTermResType";

export default class PaymentTermApi {
	static async getPaymentTerms() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PAYMENT_TERMS.name), requestOptions);
		const listPaymentTerms: PaymentTermListResType[] = await res.json();
		return listPaymentTerms
	}

	static async createPaymentTerm(paymentterms: PaymentTermReqType): Promise<CommonUpsertResType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(paymentterms)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PAYMENT_TERMS.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updatePaymentTerm(id: number, paymentterms: PaymentTermReqType): Promise<CommonUpsertResType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(paymentterms)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PAYMENT_TERMS.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getPaymentTerm(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.PAYMENT_TERMS.name, id), requestOptions);
		const data: PaymentTermResType = await res.json();
		return data;

	}
}