import { BASE_URL, TOKEN } from "../config";
import { GetProdObjectTypeEnum } from "../enums/GetProdObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import UnitReqType from "../types/req/UnitReqType";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import ProdForEsimateListResType from "../types/res/list/ProdForEsimateListResType";
import UnitListResType from "../types/res/list/UnitListResType";
import UnitResType from "../types/res/UnitResType";

export default class UnitApi {

	static async getUnits() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.UNIT.name), requestOptions);
		const listUnit: UnitListResType[] = await res.json();
		return listUnit
	}

	static async getProdForEstimate() {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.UNIT.name)
			+ getQueryString({ returnObjectType: GetProdObjectTypeEnum.PROD_FOR_ESTIMATE.enumName.toUpperCase() }),
			requestOptions);
		const listUnit: ProdForEsimateListResType[] = await res.json();
		return listUnit

	}

	static async createUnit(unit: UnitReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(unit)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.UNIT.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateUnit(id: number, unit: UnitReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(unit)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.UNIT.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getUnit(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.UNIT.name, id), requestOptions);
		const data: UnitResType = await res.json();
		return data;

	}
}
