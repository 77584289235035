import { BASE_URL, TOKEN } from "../config";
import { CalcOnTypeEnum } from "../enums/CalcOnTypeEnum";
import { GetVouOthChObjectTypeEnum } from "../enums/GetVouOthChObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import ListVouOthChargeResType from "../types/res/list/ListVouOthChargeResType";

export default class VouOthChApi {

	static async getVouOthCh(
		calOnType: CalcOnTypeEnum,
		isTaxable: boolean
	) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.VOU_OTH_CHARGE.name)
			+ getQueryString({
				returnObjectType: GetVouOthChObjectTypeEnum.VOU_OTH_CH_FOR_ESTIMATE.enumName.toUpperCase(),
				calOnType: calOnType.enumName.toUpperCase(),
				isTaxable: isTaxable
			}),
			requestOptions);
		const listVouOthCh: ListVouOthChargeResType[] = await res.json();
		return listVouOthCh

	}

}