import { BASE_URL, MENUS, TOKEN } from "../config";
import ServerNotReachableErrorCommonCudObj from "../default_objects/ServerNotReachableErrorCommonCudObj";
import ServerSideErrorCommonCudObj from "../default_objects/ServerSideErrorCommonCudObj";
import { ListMenuEnum } from "../enums/ListMenuEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import CommonGetListType from "../types/CommonGetListType";
import ColSettingResType from "../types/res/ColSettingResType";
import ErrorResType from "../types/res/ErrorResType";

export default class MenuApi {

	static async getMenus() {
		if (MENUS.val === null) {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.MENU.name), requestOptions);
			const data = await res.json();
			MENUS.val = data;
		}

		return MENUS.val;
	}

	static async getColSettingDtos(listMenu: ListMenuEnum): Promise<CommonGetListType<ColSettingResType>> {
		try {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const response = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.MENU.name,
				listMenu.id,
				ResourceEnum.COL_SETTING.name,
			), requestOptions);


			if (response.status === 200) {
				const data: ColSettingResType[] = await response.json();
				return {
					isSuccess: true,
					items: data
				};
			}
			else if (response.status === 403) {
				const data: ErrorResType = await response.json();
				return {
					isSuccess: false,
					errorRes: data
				};
			}
			else {
				return ServerSideErrorCommonCudObj;
			}
		}
		catch (e) {
			return ServerNotReachableErrorCommonCudObj;
		}
	}
}
