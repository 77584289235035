import { useEffect, useState } from "react";
import UnitApi from "../../api/UnitApi";
import MessageDialog from "../../dialogs/MessageDialog";
import OperatorEnum from "../../enums/OperatorEnum";
import OperatorHelper from "../../helpers/OperatorHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import UnitHelper from "../../helpers/UnitHelper";
import DropDownItemType from "../../types/DropDownItemType";
import UnitReqType from "../../types/req/UnitReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import UnitListResType from "../../types/res/list/UnitListResType";
import UnitResType from "../../types/res/UnitResType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddUnitProps {
	id?: number
}

function AddUnit({
	id
}: AddUnitProps) {

	const [code, setCode] = useState("");
	const [name, setName] = useState("");
	const [isDerived, setIsDerived] = useState(false);
	const [baseUnitId, setBaseUnitId] = useState<number | null>(null);
	const [operatorId, setOperatorId] = useState<number | null>(null);
	const [qtyStr, setQtyStr] = useState<string>("");
	//
	const [unitListArr, setUnitListArr] = useState<UnitListResType[]>([]);
	const [unitListArrForDd, setUnitListArrForDd] = useState<DropDownItemType[]>([]);
	const [unitListResMap, setUnitListResMap] = useState<Map<number, UnitListResType>>(new Map());

	const [operatorArrForDd, setOperatorArrForDd] = useState<DropDownItemType[]>([]);
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	//
	useEffect(() => {
		async function process() {

			UnitApi.getUnits().then((unitListArr) => {
				setUnitListArr(unitListArr);
				setUnitListArrForDd(UnitHelper.getUnitsForDd(unitListArr));
				setUnitListResMap(UnitHelper.getUnitMap(unitListArr));
			});

			setOperatorArrForDd(OperatorHelper.getOperatorsForDd());

			if (id !== undefined && id !== null) {
				fillInUi(await UnitApi.getUnit(id));
			}
		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let unit: UnitReqType = {
			name: name,
			code: code,
			isDerived: isDerived,
			baseUnitId: null,
			operatorId: null,
			qty: null,
		}

		if (isDerived) {
			unit.baseUnitId = baseUnitId;
			unit.operatorId = operatorId;
			unit.qty = parseFloat(qtyStr);
		}

		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await UnitApi.createUnit(unit);
		}
		else {
			res = await UnitApi.updateUnit(id, unit);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (unitRes: UnitResType) => {
		setName(unitRes.name);
		setCode(unitRes.code);
		setIsDerived(unitRes.isDerived);
		if (unitRes.isDerived) {
			setBaseUnitId(unitRes.baseUnitId!);
			setOperatorId(unitRes.operatorId!);
			setQtyStr(unitRes.qty?.toString()!);
		}

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setCode("");
		setIsDerived(false);
		setBaseUnitId(-1);
		setOperatorId(-1);
		setQtyStr("");
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			code === undefined
			|| code === null
			|| code === ""
		) {
			setMessageDialogMessage("Invalid code");
			setShowMessageDialog(true);
			return false;
		}



		if (isDerived) {

			if (
				baseUnitId === undefined
				|| baseUnitId === null
				|| unitListResMap.get(baseUnitId) === undefined
				|| unitListResMap.get(baseUnitId) === null
			) {
				setMessageDialogMessage("Invalid base unit");
				setShowMessageDialog(true);
				return false;
			}

			if (
				operatorId === undefined
				|| operatorId === null
				|| OperatorEnum.getById(operatorId) === undefined
				|| OperatorEnum.getById(operatorId) === null
			) {
				setMessageDialogMessage("Invalid operator");
				setShowMessageDialog(true);
				return false;
			}

			if (
				qtyStr === undefined
				|| qtyStr === null
				|| !StringHelper2.isNum(qtyStr)
			) {
				setMessageDialogMessage("Invalid base unit quantity");
				setShowMessageDialog(true);
				return false;
			}
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="inputPassword6" className="col-form-label">
									Name
								</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="inputPassword6" className="col-form-label">
									Code
								</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={code}
									onChange={(e) => setCode(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-10 offset-sm-2">
								<div className="form-check">
									<input
										className="form-check-input"
										type="checkbox"
										checked={isDerived}
										id="flexCheckDefault"
										onChange={(e) => setIsDerived(e.target.checked)}
									/>
									<label className="form-check-label" htmlFor="flexCheckDefault">
										Is Derived unit
									</label>
								</div>
							</div>
						</div>
						{
							isDerived ?
								<>
									<div className="row align-items-center mb-3">
										<div className="col-sm-2">
											<label htmlFor="cmbBaseUnit" className="col-form-label">Base Unit</label>
										</div>
										<div className="col-sm-10">
											<DownshiftSelect
												inputId={"cmbBaseUnit"}
												value={baseUnitId != null && unitListResMap?.get(baseUnitId) != null ? UnitHelper.getUnitForDd(unitListResMap?.get(baseUnitId)!) : null}
												onChange={setBaseUnitId}
												options={unitListArrForDd}
												isLoading={false}
												isDisabled={false}
												placeholder=""
											/>
										</div>
									</div>

									<div className="row align-items-center mb-3">
										<div className="col-sm-2">
											<label htmlFor="inputPassword6" className="col-form-label">
												Operator
											</label>
										</div>
										<div className="col-sm-10">
											<DownshiftSelect
												inputId={"is_pending"}
												value={operatorId != null && operatorId > 0 && OperatorEnum.getById(operatorId) != null ? OperatorHelper.getOperatorForDd(OperatorEnum.getById(operatorId)!) : null}
												onChange={(e) => setOperatorId(e)}
												options={operatorArrForDd}
												isLoading={false}
												isDisabled={false}
												placeholder=""
											/>
										</div>
									</div>

									<div className="row align-items-center mb-3">
										<div className="col-sm-2">
											<label htmlFor="inputPassword6" className="col-form-label">
												Quantity
											</label>
										</div>
										<div className="col-sm-10">
											<input
												type="text"
												className="form-control"
												value={qtyStr.toString()}
												onChange={(e) => setQtyStr(e.target.value)}
											/>
										</div>
									</div>
								</>
								:
								""
						}

					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddUnit;
