import { useEffect, useState } from "react";
import MenuApi from "../api/MenuApi";
import {
	CURRENT_LAYOUT,
	CURRENT_TITLE, MENU_STACK,
	TITLE_STACK
} from "../config";
import MenuResType from "../types/res/MenuResType";
import AddCountry from "./add/AddCountry";
import AddCustomer from "./add/AddCustomer";
import AddEstimate from "./add/AddEstimate";
import AddPaymentMode from "./add/AddPaymentMode";
import AddPaymentTerm from "./add/AddPaymentTerm";
import AddProduct from "./add/AddProduct";
import AddProductCat from "./add/AddProductCat";
import AddSalesChannel from "./add/AddSalesChannel";
import AddState from "./add/AddState";
import AddSupplier from "./add/AddSupplier";
import AddTax from "./add/AddTax";
import AddTaxCat from "./add/AddTaxCat";
import AddTradeTerm from "./add/AddTradeTerm";
import AddTransporter from "./add/AddTransporter";
import AddUnit from "./add/AddUnit";
import { Layout } from "./Layout";
import ListCountry from "./list/ListCountry";
import ListCustomer from "./list/ListCustomer";
import ListEstimate from "./list/ListEstimate";
import ListPaymentMode from "./list/ListPaymentMode";
import ListPaymentTerms from "./list/ListPaymentTerms";
import ListProduct from "./list/ListProduct";
import ListProductCat from "./list/ListProductCat";
import ListSalesChannel from "./list/ListSalesChannel";
import ListState from "./list/ListState";
import ListSupplier from "./list/ListSupplier";
import ListTax from "./list/ListTax";
import ListTaxCat from "./list/ListTaxCat";
import ListTradeTerm from "./list/ListTradeTerm";
import ListTransporter from "./list/ListTransporter";
import ListUnit from "./list/ListUnit";
import Menu from "./Menu";
import Topbar from "./Topbar";

function Container() {

	const [loading, setLoading] = useState(true);
	// const [menu, setMenu] = useState(null);
	const [componentToRender, setComponentToRender] =
		useState<JSX.Element | null>(null);
	const [pageTitle, setPageTitle] = useState<string>("Menu");

	const openMenuView: (parentMenu: MenuResType) => void = (
		parentMenu: MenuResType
	) => {
		if (!(parentMenu.id === 0)) {
			MENU_STACK.push(CURRENT_LAYOUT.val);
			TITLE_STACK.push(CURRENT_TITLE.val);
		}

		CURRENT_LAYOUT.val = (
			<Menu
				menus={parentMenu.children}
				openMenuView={openMenuView}
				openListView={openListView}
				openAddView={openAddView}
			/>
		);
		CURRENT_TITLE.val =
			parentMenu.displayName === null
				? "Menu"
				: parentMenu.displayName === ""
					? parentMenu.name
					: parentMenu.displayName;

		setPageTitle(CURRENT_TITLE.val);
		setComponentToRender(CURRENT_LAYOUT.val);
	};

	const openListView = (menu: MenuResType) => {

		switch (menu.name) {
			case Layout.COUNTRY.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCountry menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.COUNTRY.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.CUSTOMER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCustomer menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.CUSTOMER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.ESTIMATE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListEstimate menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.ESTIMATE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PAYMENT_MODE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPaymentMode menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PAYMENT_MODE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PAYMENT_TERMS.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPaymentTerms menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PAYMENT_TERMS.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PRODUCT_CAT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListProductCat menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PRODUCT_CAT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PRODUCT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListProduct menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PRODUCT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SALES_CHANNEL.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListSalesChannel menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SALES_CHANNEL.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.STATE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListState menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.STATE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SUPPLIER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListSupplier menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SUPPLIER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TAX_CAT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListTaxCat menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.TAX_CAT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TAX.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListTax menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.TAX.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TRADE_TERMS.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListTradeTerm menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.TRADE_TERMS.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TRANSPORTER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListTransporter menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.TRANSPORTER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.UNIT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListUnit menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.UNIT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
		}
	};

	const openAddView = (menu: MenuResType) => {
		switch (menu.name) {
			case Layout.COUNTRY.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddCountry />;
				CURRENT_TITLE.val = Layout.COUNTRY.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.CUSTOMER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddCustomer />;
				CURRENT_TITLE.val = Layout.CUSTOMER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.ESTIMATE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddEstimate />;
				CURRENT_TITLE.val = Layout.ESTIMATE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PAYMENT_MODE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddPaymentMode />;
				CURRENT_TITLE.val = Layout.PAYMENT_MODE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PAYMENT_TERMS.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddPaymentTerm />;
				CURRENT_TITLE.val = Layout.PAYMENT_TERMS.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PRODUCT_CAT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddProductCat />;
				CURRENT_TITLE.val = Layout.PRODUCT_CAT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PRODUCT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddProduct />;
				CURRENT_TITLE.val = Layout.PRODUCT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SALES_CHANNEL.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddSalesChannel />;
				CURRENT_TITLE.val = Layout.SALES_CHANNEL.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.STATE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddState />;
				CURRENT_TITLE.val = Layout.STATE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SUPPLIER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddSupplier />;
				CURRENT_TITLE.val = Layout.SUPPLIER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TAX_CAT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddTaxCat />;
				CURRENT_TITLE.val = Layout.TAX_CAT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TAX.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddTax />;
				CURRENT_TITLE.val = Layout.TAX.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TRADE_TERMS.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddTradeTerm />;
				CURRENT_TITLE.val = Layout.TRADE_TERMS.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TRANSPORTER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddTransporter />;
				CURRENT_TITLE.val = Layout.TRANSPORTER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.UNIT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddUnit />;
				CURRENT_TITLE.val = Layout.UNIT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
		}
	};

	const goBack = () => {
		if (MENU_STACK.length > 0) {
			CURRENT_LAYOUT.val = MENU_STACK.pop()!;
			CURRENT_TITLE.val = TITLE_STACK.pop()!;
			setPageTitle(CURRENT_TITLE.val);
			setComponentToRender(CURRENT_LAYOUT.val);
		}
	};

	useEffect(() => {
		async function process() {
			let menus = await MenuApi.getMenus();
			openMenuView(menus);
		}
		process();

		window.addEventListener('keydown', handleKeyDown);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);


	// Function to handle keyboard events
	const handleKeyDown = (event: KeyboardEvent) => {
		if (
			event.key === "Escape"
			&& !event.ctrlKey
			&& !event.altKey
			&& !event.metaKey
			&& !event.shiftKey) {
			goBack();
		}
		// Add more conditions for other shortcuts if needed
	};

	// function handleMenuClick(menu) {
	// 	setMenu(menu);
	// }

	// console.log("/* menu");
	// console.log(menu);
	// console.log("menu */");

	return (
		<div className="d-flex flex-column vh-100">
			<Topbar title={pageTitle} goBack={goBack} />
			{componentToRender}
		</div>
	);
}

export default Container;
