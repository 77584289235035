export default class ListCountrySm {

	name: string | undefined;
	code: string | undefined;

	reset(): void {
		this.name = undefined;
		this.code = undefined;
	}

	isBlank(): boolean {
		return this.name === undefined &&
			this.code === undefined;
	}
}
