import { useEffect, useState } from "react";
import UnitApi from "../../api/UnitApi";
import UnitListResType from "../../types/res/list/UnitListResType";
import MenuResType from "../../types/res/MenuResType";
import RowType from "../../types/RowType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";

interface ListUnitProps {
	menu: MenuResType;
	openAddView: (menu: MenuResType) => void;
}

function ListUnit({ menu, openAddView }: ListUnitProps) {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const colNames = [
		"Name",
		"Code",
		"Is Derived?",
		"Base Unit"
	];

	useEffect(() => {
		async function process() {

			const results: UnitListResType[] = await UnitApi.getUnits();

			let dataToReturn: RowType[] = [];
			results.forEach((result) => {
				let oneRowArray = [];
				oneRowArray.push(result.name);
				oneRowArray.push(result.code);
				oneRowArray.push(result.isDerived ? "YES" : "NO");
				let baseUnitNameStr = "";
				if (result.isDerived) {
					if (result.operatorId === 1) {
						baseUnitNameStr = "1 " + result.baseUnitName + " / " + result.num;
					} else if (result.operatorId === 2) {
						baseUnitNameStr = result.num + " " + " x " + result.baseUnitName;
					}
				}
				oneRowArray.push(baseUnitNameStr);
				dataToReturn.push({
					id: result.id,
					data: oneRowArray
				});
			});

			setRows(dataToReturn);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		console.log("edit clicked");
	};

	const handleClickDelete = () => {
		console.log("handleClickDelete");
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>
			) : null}
		</>
	);
}

export default ListUnit;
