import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import TaxCatReqType from "../types/req/TaxCatReqType";
import CommonCudType from "../types/CommonCudType";
import ErrorResType from "../types/res/ErrorResType";
import TaxCatListResType from "../types/res/list/TaxCatListResType";
import TaxCatResType from "../types/res/TaxCatResType";

export default class TaxCatApi {
	static async getTaxCats() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TAX_CAT.name), requestOptions);
		const listTaxCat: TaxCatListResType[] = await res.json();
		return listTaxCat
	}

	static async createTaxCat(taxcat: TaxCatReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(taxcat)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TAX_CAT.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateTaxCat(id: number, taxcat: TaxCatReqType): Promise<CommonCudType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(taxcat)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.TAX_CAT.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getTaxCat(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.TAX_CAT.name, id), requestOptions);
		const data: TaxCatResType = await res.json();
		return data;

	}
}
