import React, { useEffect, useState } from "react";
import TaxApi from "../../api/TaxApi";
import MessageDialog from "../../dialogs/MessageDialog";
import StringHelper2 from "../../helpers/StringHelper2";
import TaxReqType from "../../types/req/TaxReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import TaxResType from "../../types/res/TaxResType";

interface AddTaxProps {
	id?: number
}

function AddTax({
	id
}: AddTaxProps): JSX.Element {

	const [name, setName] = useState("");
	const [cgstStr, setCgstStr] = useState("");
	const [sgstStr, setSgstStr] = useState("");
	const [igstStr, setIgstStr] = useState("");
	const [note, setNote] = useState("");
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			if (id !== undefined && id !== null) {
				fillInUi(await TaxApi.getTax(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let tax: TaxReqType = {
			name: name,
			cgst: parseFloat(cgstStr),
			sgst: parseFloat(sgstStr),
			igst: parseFloat(igstStr),
			note: note
		};
		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await TaxApi.createTax(tax);
		}
		else {
			res = await TaxApi.updateTax(id, tax);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (taxResType: TaxResType) => {
		setName(taxResType.name);
		setCgstStr(taxResType.cgst.toString());
		setSgstStr(taxResType.sgst.toString());
		setIgstStr(taxResType.igst.toString());
		setNote(taxResType.note);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setCgstStr("");
		setSgstStr("");
		setIgstStr("");
		setNote("");
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			cgstStr === undefined
			|| cgstStr === null
			|| cgstStr === ""
			|| !StringHelper2.isNum(cgstStr)
		) {
			setMessageDialogMessage("Invalid CGST rate");
			setShowMessageDialog(true);
			return false;
		}

		if (
			sgstStr === undefined
			|| sgstStr === null
			|| sgstStr === ""
			|| !StringHelper2.isNum(sgstStr)
		) {
			setMessageDialogMessage("Invalid SGST rate");
			setShowMessageDialog(true);
			return false;
		}

		if (
			igstStr === undefined
			|| igstStr === null
			|| igstStr === ""
			|| !StringHelper2.isNum(igstStr)
		) {
			setMessageDialogMessage("Invalid IGST rate");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">CGST Rate</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={cgstStr}
									onChange={(e) => setCgstStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">SGST Rate</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={sgstStr}
									onChange={(e) => setSgstStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">IGST Rate</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={igstStr}
									onChange={(e) => setIgstStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Note</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={note}
									onChange={(e) => setNote(e.target.value)}
								/>
							</div>
						</div>


					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddTax;
