import React, { useEffect, useState } from "react";
import CountryApi from "../../api/CountryApi";
import StateApi from "../../api/StateApi";
import MessageDialog from "../../dialogs/MessageDialog";
import CountryHelper from "../../helpers/CountryHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import DropDownItemType from "../../types/DropDownItemType";
import StateReqType from "../../types/req/StateReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import StateResType from "../../types/res/StateResType";
import CountryLdto from "../../types/res/list/CountryLdto";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddStateProps {
	id?: number
}

function AddState({
	id
}: AddStateProps): JSX.Element {

	const [name, setName] = useState("");
	const [codeName, setCodeName] = useState("");
	const [codeNoStr, setCodeNoStr] = useState("");
	const [countryId, setCountryId] = useState<number | null>(null);
	//
	const [countryListArr, setCountryListArr] = useState<CountryLdto[]>([]);
	const [countryListArrForDd, setCountryListArrForDd] = useState<DropDownItemType[]>([]);
	const [countryListResMap, setCountryListResMap] = useState<Map<number, CountryLdto>>(new Map());
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			CountryApi.getCountryLdtos().then((response) => {
				if (response.isSuccess) {
					setCountryListArr(response.items!);
					setCountryListArrForDd(CountryHelper.getCountryLdtoDdis(response.items!));
					setCountryListResMap(CountryHelper.getCountryLdtoMap(response.items!));
				}
			});

			if (id !== undefined && id !== null) {
				fillInUi(await StateApi.getState(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let state: StateReqType = {
			name: name,
			codeName: codeName,
			codeNo: parseInt(codeNoStr),
			countryId: countryId!
		};
		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await StateApi.createState(state);
		}
		else {
			res = await StateApi.updateState(id, state);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (stateResType: StateResType) => {
		setName(stateResType.name);
		setCodeName(stateResType.codeName);
		setCodeNoStr(stateResType.codeNo.toString());
		setCountryId(stateResType.countryId);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setCodeName("");
		setCodeNoStr("");
		setCountryId(-1);
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			codeName === undefined
			|| codeName === null
			|| codeName === ""
		) {
			setMessageDialogMessage("Invalid code name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			codeNoStr === undefined
			|| codeNoStr === null
			|| !StringHelper2.isInt(codeNoStr)
		) {
			setMessageDialogMessage("Invalid code number");
			setShowMessageDialog(true);
			return false;
		}

		if (
			countryId === undefined
			|| countryId === null
			|| countryListResMap.get(countryId) === undefined
			|| countryListResMap.get(countryId) === null
		) {
			setMessageDialogMessage("Invalid country");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Code Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={codeName}
									onChange={(e) => setCodeName(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Code Number</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={codeNoStr}
									onChange={(e) => setCodeNoStr(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbCountry" className="col-form-label">Country</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={countryId != null && countryListResMap?.get(countryId) != null ? CountryHelper.getCountryForDd(countryListResMap?.get(countryId)!) : null}
									onChange={setCountryId}
									options={countryListArrForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>
					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddState;
