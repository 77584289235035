import { useEffect, useState } from "react";
import ProductApi from "../../api/ProductApi";
import ProductListResType from "../../types/res/list/ProductListResType";
import MenuResType from "../../types/res/MenuResType";
import RowType from "../../types/RowType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";

interface ListProductProps {
	menu: MenuResType;
	openAddView: (menu: MenuResType) => void;
}

function ListProduct({
	menu,
	openAddView,
}: ListProductProps): JSX.Element {
	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const colNames = [
		"Name",
		"Code",
		"Description",
		"Unit",
		"HSN",
		"Purchase Price (Raw)",
		"Polish Price",
		"Cost Price",
		"Additional Cost",
		"Min. Sales Price",
		"Wholesale Sales Price",
		"MRP",
		"Internet Sales Price",
		"Max. Sales Price",
		"Discount",
		"Product Category",
		"Tax Category",
		"Tax",
		"Note",
		"Is Sold?",
		"Is Made?",
		"Min. Quantity",
		"Reorder Quantity",
		"Supplier (Raw)",
		"Polished By Supplier",
		"Weight",
		"Created By",
		"Created At"
	];

	useEffect(() => {
		async function process() {
			const results: ProductListResType[] = await ProductApi.getProducts();

			let dataToReturn: RowType[] = [];
			results.forEach((result) => {
				let oneRowArray = [];
				oneRowArray.push(result.name);
				oneRowArray.push(result.code === null ? "" : result.code);
				oneRowArray.push(result.desc === null ? "" : result.desc);
				oneRowArray.push(result.unitName === null ? "" : result.unitName);
				oneRowArray.push(result.hsn === null ? "" : result.hsn.toString());
				oneRowArray.push(result.rawPurchasePrice === null ? "" : result.rawPurchasePrice.toString());
				oneRowArray.push(result.polishPrice === null ? "" : result.polishPrice.toString());
				oneRowArray.push(result.costPrice === null ? "" : result.costPrice.toString());
				oneRowArray.push(result.additionalCost === null ? "" : result.additionalCost.toString());
				oneRowArray.push(result.minSalesPrice === null ? "" : result.minSalesPrice.toString());
				oneRowArray.push(result.wholesaleSalesPrice === null ? "" : result.wholesaleSalesPrice.toString());
				oneRowArray.push(result.mrp === null ? "" : result.mrp.toString());
				oneRowArray.push(result.internetSalesPrice === null ? "" : result.internetSalesPrice.toString());
				oneRowArray.push(result.maxSalesPrice === null ? "" : result.maxSalesPrice.toString());
				oneRowArray.push(result.discount === null ? "" : result.discount.toString());
				oneRowArray.push(result.productCategoryName === null ? "" : result.productCategoryName);
				oneRowArray.push(result.taxCategoryName === null ? "" : result.taxCategoryName);
				oneRowArray.push(result.taxName === null ? "" : result.taxName);
				oneRowArray.push(result.note === null ? "" : result.note);
				oneRowArray.push(result.isSold ? "YES" : "NO");
				oneRowArray.push(result.isMade ? "YES" : "NO");
				oneRowArray.push(result.minQty === null ? "" : result.minQty.toString());
				oneRowArray.push(result.reorderQty === null ? "" : result.reorderQty.toString());
				oneRowArray.push(result.rawSupplierName === null ? "" : result.rawSupplierName);
				oneRowArray.push(result.polishedBySupplierName === null ? "" : result.polishedBySupplierName);
				oneRowArray.push(result.weight === null ? "" : result.weight.toString());
				oneRowArray.push(result.createdByUserName === null ? "" : result.createdByUserName);
				oneRowArray.push(result.createdAt === null ? "" : result.createdAt.toString());

				dataToReturn.push({
					id: result.id,
					data: oneRowArray
				});
			});

			setRows(dataToReturn);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		console.log("edit clicked");
	};

	const handleClickDelete = () => {
		console.log("handleClickDelete");
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>
			) : null}
		</>
	);
}

export default ListProduct;
