import { useEffect, useState } from "react";
import CustomerApi from "../../api/CustomerApi";
import CustomerListResType from "../../types/res/list/CustomerListResType";
import MenuResType from "../../types/res/MenuResType";
import RowType from "../../types/RowType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";

interface ListCustomerProps {
	menu: MenuResType;
	openAddView: (menu: MenuResType) => void;
}

function ListCustomer({
	menu,
	openAddView,
}: ListCustomerProps): JSX.Element {
	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const colNames = [
		"Name",
		"Account Type",
		"Opening Balance",
		"Opening Balance Type",
		"Address",
		"City",
		"State",
		"Pin",
		"Email",
		"GST No.",
		"Bank Name",
		"Bank Branch",
		"Bank Account Name",
		"Bank Account No.",
		"Bank IFSC",
		"Credit Days",
		"Credit Amount Limit",
		"Preferred Transport",
		"Created By",
		"Created At",
	];

	useEffect(() => {
		async function process() {

			const results: CustomerListResType[] = await CustomerApi.getCustomers();

			let dataToReturn: RowType[] = [];
			results.forEach((result) => {
				let oneRowArray = [];
				oneRowArray.push(result.name);
				oneRowArray.push(result.acctTypeName);
				oneRowArray.push(result.ob === null ? "" : result.ob.toString());
				oneRowArray.push(result.ob === null ? "" : result.obTypeId!.toString());
				oneRowArray.push(result.address === null ? "" : result.address);
				oneRowArray.push(result.city === null ? "" : result.city);
				oneRowArray.push(result.stateName === null ? "" : result.stateName);
				oneRowArray.push(result.pin === null ? "" : result.pin);
				oneRowArray.push(result.email === null ? "" : result.email);
				oneRowArray.push(result.gstNo === null ? "" : result.gstNo);
				oneRowArray.push(result.bankName === null ? "" : result.bankName);
				oneRowArray.push(result.bankBranchName === null ? "" : result.bankBranchName);
				oneRowArray.push(result.bankAcctName === null ? "" : result.bankAcctName);
				oneRowArray.push(result.bankAcctNo === null ? "" : result.bankAcctNo);
				oneRowArray.push(result.bankIfsc === null ? "" : result.bankIfsc);
				oneRowArray.push(result.creditDays === null ? "" : result.creditDays.toString());
				oneRowArray.push(result.creditAmtLimit === null ? "" : result.creditAmtLimit.toString());
				oneRowArray.push(result.prefTransName === null ? "" : result.prefTransName);
				oneRowArray.push(result.createdByUserName === null ? "" : result.createdByUserName);
				oneRowArray.push(result.createdAt === null ? "" : result.createdAt.toString());

				dataToReturn.push({
					id: result.id,
					data: oneRowArray
				});
			});

			setRows(dataToReturn);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		console.log("edit clicked");
	};

	const handleClickDelete = () => {
		console.log("handleClickDelete");
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>
			) : null}
		</>
	);
}

export default ListCustomer;
