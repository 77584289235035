import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import SalesChannelReqType from "../types/req/SalesChannelReqType";
import CommonUpsertResType from "../types/res/CommonUpsertResType";
import ErrorResType from "../types/res/ErrorResType";
import SalesChannelListResType from "../types/res/list/SalesChannelListResType";
import SalesChannelResType from "../types/res/SalesChannelResType";

export default class SalesChannelApi {
	static async getSalesChannels() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES_CHANNEL.name), requestOptions);
		const listSalesChannel: SalesChannelListResType[] = await res.json();
		return listSalesChannel
	}

	static async createSalesChannel(saleschannel: SalesChannelReqType): Promise<CommonUpsertResType> {

		const requestOptions = {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(saleschannel)
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.SALES_CHANNEL.name), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async updateSalesChannel(id: number, saleschannel: SalesChannelReqType): Promise<CommonUpsertResType> {

		const requestOptions = {
			method: "PUT",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(saleschannel)
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_CHANNEL.name, id), requestOptions);

		if (res.status !== 201) {
			const data: ErrorResType = await res.json();
			return {
				isSuccess: false,
				errorRes: data
			}
		}

		return {
			isSuccess: true,
		}

	}

	static async getSalesChannel(id: number) {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, ResourceEnum.SALES_CHANNEL.name, id), requestOptions);
		const data: SalesChannelResType = await res.json();
		return data;

	}
}