export default class ListStateSm {

	name: string | undefined;
	codeName: string | undefined;
	codeNo: string | undefined;
	countryId: number | undefined;

	reset(): void {
		this.name = undefined;
		this.codeName = undefined;
		this.codeNo = undefined;
		this.countryId = undefined;
	}

	isBlank(): boolean {
		return this.name === undefined &&
			this.codeName === undefined &&
			this.codeNo === undefined &&
			this.countryId === undefined
			;
	}
}
