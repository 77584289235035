import { useEffect, useState } from "react";
import TransporterApi from "../../api/TransporterApi";
import { BASE_URL, TOKEN } from "../../config";
import useConfirm from "../../dialogs/ConfirmDialog";
import TransporterListResType from "../../types/res/list/TransporterListResType";
import MenuResType from "../../types/res/MenuResType";
import RowType from "../../types/RowType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";

interface ListTransporterProps {
	menu: MenuResType;
	openAddView: (menu: MenuResType) => void;
}

function ListTransporter({
	menu,
	openAddView,
}: ListTransporterProps): JSX.Element {

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setselectedId] = useState<number | null>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	const colNames = [
		"Name",
		"Transporter Id"
	];

	useEffect(() => {
		async function process() {

			const results: TransporterListResType[] = await TransporterApi.getTransporters();

			let dataToReturn: RowType[] = [];
			results.forEach((result) => {
				let oneRowArray = [];
				oneRowArray.push(result.name);
				oneRowArray.push(result.transporterId);
				dataToReturn.push({
					id: result.id,
					data: oneRowArray
				});
			});

			setRows(dataToReturn);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickDelete = async () => {
		console.log(selectedId);
		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		console.log(choice);
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const handleCloseDeleteDialog = () => {

	};

	const handleCloseMessageDialog = () => {
		setShowMessageDialog(false);
	};

	const doDelete = () => {
		console.log("deleted");
	};

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setselectedId}
				/>
			) : null}


		</>
	);
}

export default ListTransporter;
