import React, { useEffect, useState } from "react";
import PaymentTermApi from "../../api/PaymentTermApi";
import MessageDialog from "../../dialogs/MessageDialog";
import PaymentTermReqType from "../../types/req/PaymentTermReqType";
import CommonUpsertResType from "../../types/res/CommonUpsertResType";
import PaymentTermResType from "../../types/res/PaymentTermResType";

interface AddPaymentTermProps {
	id?: number
}

function AddPaymentTerm({
	id
}: AddPaymentTermProps): JSX.Element {

	const [name, setName] = useState("");
	const [value, setValue] = useState("");
	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			if (id !== undefined && id !== null) {
				fillInUi(await PaymentTermApi.getPaymentTerm(id));
			}

		}
		process();
	}, []);

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

		let paymentTerm: PaymentTermReqType = {
			name: name,
			value: value
		};
		let res: CommonUpsertResType;

		if (id === undefined || id === null) {
			res = await PaymentTermApi.createPaymentTerm(paymentTerm);
		}
		else {
			res = await PaymentTermApi.updatePaymentTerm(id, paymentTerm);
		}

		if (!res!.isSuccess) {
			setMessageDialogMessage(res!.errorRes?.message!);
			setShowMessageDialog(true);
		}
		else {
			setMessageDialogMessage("Saved");
			setShowMessageDialog(true);
			clearBoxes();
		}

	}

	const fillInUi = (paymentTermResType: PaymentTermResType) => {
		setName(paymentTermResType.name);
		setValue(paymentTermResType.value);
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setName("");
		setValue("");
	};

	const isValid = (): boolean => {

		if (
			name === undefined
			|| name === null
			|| name === ""
		) {
			setMessageDialogMessage("Invalid name");
			setShowMessageDialog(true);
			return false;
		}

		if (
			value === undefined
			|| value === null
			|| value === ""
		) {
			setMessageDialogMessage("Invalid value");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>
					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">Value</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={value}
									onChange={(e) => setValue(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
			</div>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />

		</>
	);
}

export default AddPaymentTerm;
