import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import EstimateStatusListResType from "../types/res/list/EstimateStatusListResType";

export default class EstimateStatusApi {

	static async getEstimateStatuses() {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.ESTIMATE_STATUS.name),
			requestOptions);
		const results: EstimateStatusListResType[] = await res.json();
		return results;

	}

	

}